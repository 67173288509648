import * as React from "react"
import { graphql } from "gatsby";

import Container from '../components/layout/container'
import SEO from '../components/seo'
import Search from '../../search/index'

// styles
import "./../styling/libraries.scss"
import { typescale } from "../styling/typescale";

// data
export const query = graphql`
  query SearchPage {
    sanityPage(slug: {current: {eq: "api-sok"}}) {
      id
      title
      displayTitle
      intro
    }
  }
`

// markup
const SearchPage = ({data}) => {

  const pageData = data ? data.sanityPage : null
  return (
    <Container>

      {console.log('pageData', pageData.intro)}

      <SEO 
        title="Søkeside"
        description={pageData.intro ? pageData.intro : ''}
        pathname="/api-sok"
        // image={data.allSanityPage.edges && data.allSanityPage.edges[0].node.mainImage.asset.fixed}
      />

      {/* {console.log('data', data)} */}
      <div className="container">

        <div className="section py-0">
          
          <div className="columns">
            <div className="column is-6 mt-6">

              {pageData.displayTitle 
                ? <h1 style={typescale['title-1']}>{pageData.displayTitle}</h1> 
                : <h1 style={typescale['title-1']}>{pageData.title}</h1>
              }

              {/* {pageData.intro && <p className="is-size-5">{pageData.intro}</p>} */}

            </div>
          </div>

        </div>

      </div>

      <div className="container">

        <div className="columns">

          <div className="column">

            <div className="section">

              <Search />
            
            </div>

          </div>

        </div>

      </div>
      
    </Container>
  )
}

export default SearchPage
