import React from 'react';

import { Link } from "@reach/router"
import { colors } from '../src/styling/colors';

const Study = ({
  handleClick,
  orgName, 
  published, 
  updated, 
  title, 
  condition, 
  location, 
  gender, 
  minAge, 
  maxAge, 
  ageCat,
  id,
  sanityResponse
}) => {

  return(

    <tr>
      <td>
        <Link to={`${id}`}>{id}</Link>
      </td>
      <td>{title}</td>

      <td style={{'background': colors.blue01}}>

        {sanityResponse.filter(item => item.id === id.join())[0] && 
          sanityResponse.filter(item => item.id === id.join())[0].title}

      </td>

      <td>{gender}</td>
      <td>{orgName}</td>


      <td style={{'background': colors.blue01}}>

        {/* {console.log('sanityResponse', 
          sanityResponse.filter(item => item.id === id.join())[0]
        )} */}

        {sanityResponse.filter(item => item.id === id.join())[0] && 
          sanityResponse.filter(item => item.id === id.join())[0].orgName}

      </td>

      <td>{location.join(', ')}</td>
      <td>{ageCat.join(', ')}</td>
      <td>{minAge}</td>
      <td>{maxAge}</td>
      <td>{condition.join(', ')}</td>

    </tr>

  )

}

export default Study;