import React from "react";
import { css } from '@emotion/css';

const styling = css`
  display: block;

  .label {
    display: block;
  }

  input {
    border: 0;
    padding: .5em;
    font-size: 1em;
  }

  margin-bottom: 1em;

`

const Input = (props) => {
    return (
      <label className={styling}>
        <span className="label">{props.label}</span>
        <input
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      </label>
    )
}

export default Input;