import React from 'react';

const RadioButton = ({type, id, name, label, checked, onChange }) => {

  return (
    
    <label style={{'margin': '0 .5em 0 0'}}>

      <input
        type={type}
        id={id}
        value={id}
        name={name}
        aria-label={label}
        // aria-checked={checked}
        checked={checked}
        onChange={onChange}
      />

      <span style={{'margin': '0 0 0 .5em'}}>
        {label}
      </span>

    </label>

  )

}
export default RadioButton