import React, { useState, useEffect } from "react";
import { Link } from "@reach/router"
import { css } from '@emotion/css';
import { colors } from '../src/styling/colors'

const tableStyling = css`
  table, th, td {
    border: 1px solid black;
    padding: .5em;
    font-size: 14px;
  }
  th {
    background: ${colors.blue01};
    font-weight: 600;
  }
`

const FullStudy = ({id}) => {

    const [armsInterventions, setArmsInterventions] = useState('');
    const [description, setDescription] = useState('');
    const [identification, setIdentification] = useState('');
    const [design, setDesign] = useState('');
    const [eligibility, setEligibility] = useState('');
    const [outcomes, setOutcomes] = useState('');
    const [oversight, setOversight] = useState('');
    const [sponsorCollaborators, setSponsorCollaborators] = useState('');
    const [status, setStatus] = useState('');
    const [conditions, setConditions] = useState('');
    const [contactsLocations, setContactsLocations] = useState('');
    
    useEffect(() => {

        fetch(`https://clinicaltrials.gov/api/query/full_studies?expr=${id}&min_rnk=1&max_rnk=&fmt=json`)
        .then(data => data.json())
        .then(json => {
          const response = json.FullStudiesResponse

          // console.log('response ProtocolSection', 
          //   response.FullStudies
          //     .map(item => item.Study.ProtocolSection)
          // )

          const ArmsInterventionsModule = response.FullStudies
              .map(item => item.Study.ProtocolSection.ArmsInterventionsModule)

          const IdentificationModule = response.FullStudies
              .map(item => item.Study.ProtocolSection.IdentificationModule)

          const DescriptionModule = response.FullStudies
              .map(item => item.Study.ProtocolSection.DescriptionModule)

          const DesignModule = response.FullStudies
              .map(item => item.Study.ProtocolSection.DesignModule)

          const EligibilityModule = response.FullStudies
              .map(item => item.Study.ProtocolSection.EligibilityModule)

          const OutcomesModule = response.FullStudies
            .map(item => item.Study.ProtocolSection.OutcomesModule)

          const OversightModule = response.FullStudies
            .map(item => item.Study.ProtocolSection.OversightModule)

          const SponsorCollaboratorsModule = response.FullStudies
            .map(item => item.Study.ProtocolSection.SponsorCollaboratorsModule)

          const StatusModule = response.FullStudies
            .map(item => item.Study.ProtocolSection.StatusModule)

          const ConditionsModule = response.FullStudies
            .map(item => item.Study.ProtocolSection.ConditionsModule)

          const ContactsLocationsModule = response.FullStudies
            .map(item => item.Study.ProtocolSection.ContactsLocationsModule)

          setArmsInterventions(ArmsInterventionsModule)
          setIdentification(IdentificationModule)
          setDescription(DescriptionModule)
          setDesign(DesignModule)
          setEligibility(EligibilityModule)
          setOutcomes(OutcomesModule)
          setOversight(OversightModule)
          setSponsorCollaborators(SponsorCollaboratorsModule)
          setStatus(StatusModule)
          setConditions(ConditionsModule)
          setContactsLocations(ContactsLocationsModule)

          // console.log('ContactsLocationsModule', ContactsLocationsModule)

        })  


    }, id)

    return (
    // <div>fullstudy</div>
        <div style={{'padding': '2em', 'background': colors.blue02}}>

        <Link to="/api-sok/">Tilbake</Link>
        {/* <button onClick={() => clearSelectedStudy()}>Tilbake</button> */}

        <table className={tableStyling}>
          <tbody>
            <tr>
                <th>id</th>
                <td>{id}</td>
            </tr>
            <tr>
              <th>Identification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Acronym</th>
                      <td>{identification && identification[0].Acronym}</td>
                    </tr>
                    <tr>
                      <th>Brief title</th>
                      <td>{identification && identification[0].BriefTitle}</td>
                    </tr>
                    <tr>
                      <th>NCTId</th>
                      <td>{identification && identification[0].NCTId}</td>
                    </tr>
                    <tr>
                      <th>Official Title</th>
                      <td>{identification && identification[0].OfficialTitle}</td>
                    </tr>
                    <tr>
                      <th>Org Study Id Info</th>
                      <td>OrgStudyId: {identification && identification[0].OrgStudyIdInfo.OrgStudyId}</td>
                    </tr>
                    <tr>
                      <th>Organization</th>
                      <td>
                        <strong>OrgClass:</strong> {identification && identification[0].Organization.OrgClass}
                        <br /> 
                        <strong>OrgFullName:</strong> {identification && identification[0].Organization.OrgFullName}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Brief Summary</th>
                      <td>{description && description[0].BriefSummary}</td>
                    </tr>
                    <tr>
                      <th>Detailed Description</th>
                      <td>{description && description[0].DetailedDescription}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>ArmsInterventions</th>
              <td>
                <table>
                  <tbody>
                  <tr>
                    <th>Arm Group List</th>
                    <td>

                      <table>
                        <tbody>
                          <tr>
                            <th>
                              Arm Group
                            </th>
                            <td>

                              {armsInterventions && armsInterventions[0].ArmGroupList.ArmGroup
                                .map((item, index) => 
                                  <table className="mb-5" key={`armsInterventions-${index}`}>
                                    <tbody>
                                      <tr>
                                        <th>Arm Group Description</th>
                                        <td>
                                          {item.ArmGroupDescription}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Arm Group Intervention List</th>
                                        <td>
                                          {item.ArmGroupInterventionList && item.ArmGroupInterventionList.ArmGroupInterventionName.join(', ')}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Arm Group Label</th>
                                        <td>
                                          {item.ArmGroupLabel}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Arm Group Type</th>
                                        <td>
                                          {item.ArmGroupType}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )}

                            </td>
                          </tr>
                        </tbody>
                      </table>
                        
                        
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>Conditions</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Condition List</th>
                      <td>
                        {conditions && conditions[0].ConditionList && conditions[0].ConditionList.Condition}
                      </td>
                    </tr>
                    <tr>
                      <th>Keyword List</th>
                      <td>
                        {conditions && conditions[0].KeywordList && conditions[0].KeywordList.Keyword}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </td>
            </tr>
            <tr>
              <th>Contacts Locations</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Central Contact List</th>
                      <td>
                        {contactsLocations && contactsLocations[0].CentralContactList && contactsLocations[0].CentralContactList.CentralContact.map((item, index) => 
                          <table className="mb-4" key={`contactsLocations-${index}`}>
                            <tbody>
                              <tr>
                                <th>Central Contact Name</th>
                                <td>
                                  {item.CentralContactName}
                                </td>
                              </tr>
                              <tr>
                                <th>Central Contact EMail</th>
                                <td>
                                  {item.CentralContactEMail}
                                </td>
                              </tr>
                              <tr>
                                <th>Central Contact Phone</th>
                                <td>
                                  {item.CentralContactPhone}
                                </td>
                              </tr>
                              <tr>
                                <th>Central Contact Role</th>
                                <td>
                                  {item.CentralContactRole}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Location List</th>
                      <td>
                        {contactsLocations && contactsLocations[0].LocationList.Location.map((item, index) => 
                          <table className="mb-4" key={`contactLocations.Location-${index}`}>
                            <tbody>
                              <tr>
                                <th>Location City</th>
                                <td>{item.LocationCity}</td>
                              </tr>
                              <tr>
                                <th>Location Contact List</th>
                                <td>{item.LocationContactList && item.LocationContactList.LocationContact.map((item, index) => 
                                  <table key={`LocationContactList.LocationContact-${index}`}>
                                    <tbody>
                                      <tr>
                                        <th>Location Contact EMail</th>
                                        <td>{item.LocationContactEMail}</td>
                                      </tr>
                                      <tr>
                                        <th>Location Contact Name</th>
                                        <td>{item.LocationContactName}</td>
                                      </tr>
                                      <tr>
                                        <th>Location Contact Phone</th>
                                        <td>{item.LocationContactPhone}</td>
                                      </tr>
                                      <tr>
                                        <th>Location Contact Role</th>
                                        <td>{item.LocationContactRole}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Location Country</th>
                                <td>{item.LocationCountry}</td>
                              </tr>
                              <tr>
                                <th>Location Facility</th>
                                <td>{item.LocationFacility}</td>
                              </tr>
                              <tr>
                                <th>Location Status</th>
                                <td>{item.LocationStatus}</td>
                              </tr>
                              <tr>
                                <th>Location Zip</th>
                                <td>{item.LocationZip}</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            
            <tr>
              <th>Design</th>
              <td>
                <table className={tableStyling}>
                  <tbody>
                    <tr>
                      <th>Design info</th>
                      <td>
                          Design allocation: {design && design[0].DesignInfo.DesignAllocation}
                          <br />
                          DesignInterventionModel: {design && design[0].DesignInfo.DesignInterventionModel}
                          <br />
                          DesignInterventionModelDescription: {design && design[0].DesignInfo.DesignInterventionModelDescription}
                          <br />
                          DesignMaskingInfo: {design && design[0].DesignInfo.DesignMaskingInfo && design[0].DesignInfo.DesignMaskingInfo.DesignMasking}

                      </td>
                    </tr>
                    <tr>
                      <th>EnrollmentInfo</th>
                      <td>
                          EnrollmentCount: {design && design[0].EnrollmentInfo && design[0].EnrollmentInfo.EnrollmentCount}
                          <br/>
                          EnrollmentType: {design && design[0].EnrollmentInfo && design[0].EnrollmentInfo.EnrollmentType}
                      </td>
                    </tr>
                    <tr>
                      <th>Phase list</th>
                      <td>{design && design[0].PhaseList && design[0].PhaseList.Phase}</td>
                    </tr>
                    <tr>
                      <th>Study type</th>
                      <td>{design && design[0].StudyType}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>Eligibility</th>
              <td>
                <table className={tableStyling}>
                  <tbody>

                    <tr>
                      <th>
                        EligibilityCriteria
                      </th>
                      <td>
                        {eligibility && eligibility[0].EligibilityCriteria}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Gender
                      </th>
                      <td>
                        {eligibility && eligibility[0].Gender}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Gender based
                      </th>
                      <td>
                        {eligibility && eligibility[0].GenderBased}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Gender Description
                      </th>
                      <td>
                        {eligibility && eligibility[0].GenderDescription}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Healthy Volunteers
                      </th>
                      <td>
                        {eligibility && eligibility[0].HealthyVolunteers}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Maximum Age
                      </th>
                      <td>
                        {eligibility && eligibility[0].MaximumAge}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Minimum Age
                      </th>
                      <td>
                        {eligibility && eligibility[0].MinimumAge}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Std Age List
                      </th>
                      <td>
                        {eligibility && eligibility[0].StdAgeList && eligibility[0].StdAgeList.StdAge
                          .join(', ')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>
                Outcomes
              </th>
              <td>
                <table className={tableStyling}>
                  <tbody>
                    <tr>
                      <td>
                        <ol style={{'listStyle': 'none'}}>
                          {outcomes && outcomes[0].PrimaryOutcomeList && outcomes[0].PrimaryOutcomeList.PrimaryOutcome
                              .map((item, index) => 
                                <li key={`outcomes-PrimaryOutcomeList-${index}`}>
                                  <table className={tableStyling} className="mb-4">
                                    <tbody>
                                      <tr>
                                        <th>Description</th>
                                        <td>{item.PrimaryOutcomeDescription}</td>
                                      </tr>
                                      <tr>
                                        <th>Measure</th>
                                        <td>{item.PrimaryOutcomeMeasure}</td>
                                      </tr>
                                      <tr>
                                        <th>Time Frame</th>
                                        <td>{item.PrimaryOutcomeTimeFrame}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </li>
                            )
                          }
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>Oversight</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Is FDA Regulated Device</th>
                      <td>{oversight && oversight[0].IsFDARegulatedDevice}</td>
                    </tr>
                    <tr>
                      <th>Is FDA Regulated Drug</th>
                      <td>{oversight && oversight[0].IsFDARegulatedDrug}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <th>Sponsor Collaborators</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Collaborator List</th>
                      <td>
                        {sponsorCollaborators && sponsorCollaborators[0].CollaboratorList && sponsorCollaborators[0].CollaboratorList.Collaborator
                          .map((item, index) => 
                            <table key={`sponsorCollaborators ${index}`} className="mb-4">
                              <tbody>
                                <tr>
                                  <th>Collaborator Name</th>
                                  <td>{item.CollaboratorName}</td>
                                </tr>
                                <tr>
                                  <th>Collaborator Class</th>
                                  <td>{item.CollaboratorClass}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th>Lead Sponsor</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>Lead Sponsor Class</th>
                              <td>
                                {sponsorCollaborators && sponsorCollaborators[0].LeadSponsor && sponsorCollaborators[0].LeadSponsor.LeadSponsorClass}
                              </td>
                            </tr>
                            <tr>
                              <th>Lead Sponsor Name</th>
                              <td>
                                {sponsorCollaborators && sponsorCollaborators[0].LeadSponsor && sponsorCollaborators[0].LeadSponsor.LeadSponsorName}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>Responsible Party</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>Responsible Party Type</th>
                              <td>
                                {sponsorCollaborators && sponsorCollaborators[0].ResponsibleParty && sponsorCollaborators[0].ResponsibleParty.ResponsiblePartyType}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Completion Date Struct</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>
                                Completion Date
                              </th>
                              <td>
                                {status && status[0].CompletionDateStruct && status[0].CompletionDateStruct.CompletionDate}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                Completion Date Type
                              </th>
                              <td>
                                {status && status[0].CompletionDateStruct && status[0].CompletionDateStruct.CompletionDateType}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>Expanded Access Info</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>
                                HasExpandedAccess
                              </th>
                              <td>
                                {status && status[0].CompletionDateStruct && status[0].ExpandedAccessInfo.HasExpandedAccess}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Update Post Date Struct</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>Last Update Post Date</th>
                              <td>
                                {status && status[0].LastUpdatePostDateStruct && status[0].LastUpdatePostDateStruct.LastUpdatePostDate}
                              </td>
                            </tr>
                            <tr>
                              <th>Last Update Post Date Type</th>
                              <td>
                                {status && status[0].LastUpdatePostDateStruct && status[0].LastUpdatePostDateStruct.LastUpdatePostDateType}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>Last Update Submit Date</th>
                      <td>
                        {status && status[0].LastUpdateSubmitDate}
                      </td>
                    </tr>
                    <tr>
                      <th>Overall Status</th>
                      <td>
                        {status && status[0].OverallStatus}
                      </td>
                    </tr>
                    <tr>
                      <th>Primary Completion Date Struct</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>Primary Completion Date</th>
                              <td>
                                {status && status[0].PrimaryCompletionDateStruct && status[0].PrimaryCompletionDateStruct.PrimaryCompletionDate}
                              </td>
                            </tr>
                            <tr>
                              <th>Primary Completion Date Type</th>
                              <td>
                                {status && status[0].PrimaryCompletionDateStruct && status[0].PrimaryCompletionDateStruct.PrimaryCompletionDateType}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <th>Start Date Struct</th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>Start Date</th>
                              <td>
                                {status && status[0].StartDateStruct && status[0].StartDateStruct.StartDate}
                              </td>
                            </tr>
                            <tr>
                              <th>Start Date Type</th>
                              <td>
                                {status && status[0].StartDateStruct && status[0].StartDateStruct.StartDateType}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Status Verified Date
                      </th>
                      <td>
                        {status && status[0].StatusVerifiedDate && status[0].StatusVerifiedDate}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Study First Post Date Struct
                      </th>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>Study First Post Date</th>
                              <td>
                                {status && status[0].StudyFirstPostDateStruct && status[0].StudyFirstPostDateStruct.StudyFirstPostDate}
                              </td>
                            </tr>
                            <tr>
                              <th>Study First Post Date Type</th>
                              <td>
                                {status && status[0].StudyFirstPostDateStruct && status[0].StudyFirstPostDateStruct.StudyFirstPostDateType}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>StudyFirstSubmitDate</th>
                      <td>
                        {status && status[0].StudyFirstSubmitDate}
                      </td>
                    </tr>
                    <tr>
                      <th>StudyFirstSubmitQCDate</th>
                      <td>
                        {status && status[0].StudyFirstSubmitQCDate}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
}
  
export default FullStudy;