
import React, { useState, useEffect, useRef } from "react";
import { css } from '@emotion/css';
import {Router} from '@reach/router'

import Button from '../src/components/editorial/button'
import Input from './input'
import StudyList from './studyList'
import FullStudy from './fullStudy'
import RadioButton from './radioButton'

import { colors } from '../src/styling/colors'
import "../src/styling/libraries.scss"

const searchFormStyling = css`
  background: ${colors.green01};
  border: 1px solid ${colors.green03};
  padding: 2em;
  margin-bottom: 2em;
`

const searchStyling = css`
  background: ${colors.green02};
  padding: 2em;
`

const Main = props => {

  const [searchTerm, setSearchTerm] = useState('cancer');
  const [searchPlaceTerm, setSearchPlaceTerm] = useState('Bergen');
  const [ageTerm, setAgeTerm] = useState('60');

  // const [placeSearchString, setPlaceSearchString] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [sanitySearchResponse, setSanitySearchResponse] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  
  const radioButtons = [
    {
      id: "Any",
      name: 'any',
      label: 'Any'
    },
    {
      id: "All",
      name: 'all',
      label: 'All'
    },
    {
      id: "Male",
      name: 'male',
      label: 'Male'
    },
    {
      id: "Female",
      name: 'female',
      label: 'Female'
    },
  ];
  const [checkedValue, setIsChecked] = useState('Any');

  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }

  // Changes state when user adjusts input field in form  
  const searchChangeHandler = (e) => {
      setSearchTerm(e.target.value.toLowerCase());
  };

  const searchPlaceChangeHandler = (e) => {
      setSearchPlaceTerm(e.target.value.toLowerCase());
      // setPlaceSearchString(`+AND+%28AREA%5BLocationCity%5D${encodeURI(e.target.value)}%29`);
  };

  const searchAgeChangeHandler = (e) => {
    setAgeTerm(e.target.value.toLowerCase());
  };

  // Runs on form submit
  const searchResponseHandler = (e) => {

    // MED.hjelper Sanity API
    const query = '*[_type == "study"]';
    const queryUrl = 'https://8njkenaf.apicdn.sanity.io/v2021-03-25/data/query/production'
    const url = `${queryUrl}?query=${encodeURIComponent(query)}`

    fetch(url)
      .then(data => data.json())
      .then(json => {
        const results = json && json.result

        setSanitySearchResponse(results);
      }
    )

    // Clinical Trials API - study_fields
    const ageParsed = parseInt(ageTerm);
    e.preventDefault();
    fetch(`https://clinicaltrials.gov/api/query/study_fields?expr=
      ${encodeURI(searchTerm)}
      ${searchPlaceTerm ? `+AND+%28AREA%5BLocationCity%5D${encodeURI(searchPlaceTerm)}%29` : ''}
      &fields=NCTId%2CBriefTitle%2CCondition,LocationCity,OrgFullName,EligibilityCriteria,gender,StdAge,MaximumAge,MinimumAge&min_rnk=1&max_rnk=&fmt=json`)
    .then(data => data.json())
    .then(json => {
      
      if (json.StudyFieldsResponse && json.StudyFieldsResponse.StudyFields) {
        const studies = json.StudyFieldsResponse.StudyFields.map(study => ({
            id: study.NCTId,
            title: study.BriefTitle,
            condition: study.Condition, 
            location: study.LocationCity,
            orgName : study.OrgFullName,
            description: study.DetailedDescription,
            gender: study.Gender,
            maxAge: study.MaximumAge,
            minAge: study.MinimumAge,
            ageCat: study.StdAge
        }))
        .filter(study => !ageTerm || (parseInt(study.minAge) <= ageParsed && parseInt(study.maxAge) >= ageParsed))
        .filter(study => (!checkedValue || checkedValue == 'Any') || (study.gender == checkedValue));
        setSearchResponse(studies);
        setIsLoaded(true);
      }
    })   
  }



  // On first load
  useEffect(() => {

    const query = '*[_type == "study"]';
    const queryUrl = 'https://8njkenaf.apicdn.sanity.io/v2021-03-25/data/query/production'
    const url = `${queryUrl}?query=${encodeURIComponent(query)}`

    fetch(url)
      .then(data => data.json())
      .then(json => {
        const results = json && json.result

        setSanitySearchResponse(results);
      }
    )

    const ageParsed = parseInt(ageTerm);

    fetch(`https://clinicaltrials.gov/api/query/study_fields?expr=
      ${encodeURI(searchTerm)}
      ${`+AND+%28AREA%5BLocationCity%5D${encodeURI(searchPlaceTerm)}%29`}
      &fields=NCTId,BriefTitle,Condition,+LocationCity,+OrgFullName,+EligibilityCriteria,+gender,+StdAge,+MaximumAge,+MinimumAge&min_rnk=1&max_rnk=&fmt=json`)
    .then(data => data.json())
    .then(json => {

      const studies = json.StudyFieldsResponse.StudyFields.map(study => ({
        id: study.NCTId,
        title: study.BriefTitle,
        condition: study.Condition, 
        location: study.LocationCity,
        orgName : study.OrgFullName,
        description: study.DetailedDescription,
        gender: study.Gender,
        maxAge: study.MaximumAge,
        minAge: study.MinimumAge,
        ageCat :study.StdAge
      }))
      .filter(study => !ageTerm || (parseInt(study.minAge) <= ageParsed && parseInt(study.maxAge) >= ageParsed))
      .filter(study => (!checkedValue || checkedValue == 'Any') || (study.gender == checkedValue));
      setSearchResponse(studies);
      setIsLoaded(true);
    })

  }, [])


  return <div>
    <form
      id="clinical-studies-search-form"
      className={`${searchFormStyling}`}
      onSubmit={searchResponseHandler}>

      <h2 className="title">Søk</h2>

      <div className="columns mb-0">

        <div className="column">
          <Input 
            onChange={searchChangeHandler} 
            value={searchTerm} 
            label="Diagnose" 
            placeholder="cancer" 
          />
        </div>

        <div className="column">
          <Input 
            onChange={searchPlaceChangeHandler} 
            value={searchPlaceTerm} 
            label="Plassering" 
            placeholder="Bergen" 
          />
        </div>

        <div className="column">
          <Input 
            onChange={searchAgeChangeHandler} 
            value={ageTerm} 
            label="Alder" 
            placeholder="50"
          />
        </div>

        <div className="column">
          <fieldset>
            <legend style={{'display': 'block', 'marginBottom': '.75em'}}>
              <span className="label">Kjønn</span>
            </legend>

            {radioButtons.map(item => {
              return (
                <div className="block">
                  <RadioButton
                    type="radio"
                    key={item.id}
                    id={item.id}
                    name="radioGroup"
                    label={item.label}
                    checked={checkedValue === item.id}
                    onChange={() => setIsChecked(item.id)}
                  />
                </div>
              );
            })}
          </fieldset>
        </div>

      </div>

      <Button
        onClick={() => {
          window.dataLayer.push({
            event: 'clinicalSearchFormSubmission',
            searchTerm: searchTerm,
            searchPlaceTerm: searchPlaceTerm,
            ageTerm: ageTerm
          });
        }}
        buttonText="Søk"
      />

    </form>
    
    <StudyList 
      response={searchResponse}
      sanityResponse={sanitySearchResponse}
    />

  </div>
}

const Search = () => {
    
  return(
    <div className={searchStyling}>

      <Router basepath="/api-sok">
        <FullStudy path="/:id" />
        <Main path="/" />
      </Router>

    </div>
  )
}

export default Search;