import React from "react";
import Study from './study';

import { css } from '@emotion/css';

const tableStyling = css`
  table, th, td {
    border: 1px solid black;
    padding: .5em;
    font-size: .85em;
  }
`

const StudyList = ({response, sanityResponse, handleClick}, ) => {

  // console.log('sanityResponse', sanityResponse)

  const searcResults = response.map(({
    id, 
    orgName, 
    updated,      
    published, 
    summary, 
    description, 
    title, 
    condition, 
    location, 
    gender, 
    minAge, 
    maxAge, 
    ageCat
  }) => (

    <Study
      sanityResponse={sanityResponse}
      handleClick={(id) => handleClick(id)}
      key={id}
      id={id}
      orgName={orgName}
      updated={updated}
      published={published}
      summary={summary}
      description={description}
      title={title}
      condition={condition}
      location={location}
      gender={gender}
      minAge={minAge}
      maxAge={maxAge}
      ageCat={ageCat}
    />

  ))

  return (
    searcResults.length > 0 &&
      <table className={tableStyling}>
        <thead style={{'background': '#eee'}}>
          <tr>
            <td>ID</td>
            <td>Tittel fra Clinicaltrials</td>
            <td>Tilpasset tittel</td>
            <td>Kjønn</td>
            <td>Organisasjon</td>
            <td>Tilpasset organisasjonsnavn</td>
            <td>Sted - by</td>
            <td>Aldersgruppe</td>
            <td>Min. age</td>
            <td>Max. age</td>
            <td>Diagnose</td>
          </tr>
        </thead>

        <tbody>
          {searcResults}
        </tbody>

      </table>
  )
}

export default StudyList;